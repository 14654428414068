<template>
  <ActivationCard />
</template>

<script>
import ActivationCard from '@/components/ActivationCard';

export default {
  components: {
    ActivationCard
  }
};
</script>
